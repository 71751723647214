import { Button, Container } from "@mui/material"
import { Box } from "@mui/system"
import { PageProps } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { roomsData } from "../data/roomsData"
import { ReactCarousel } from "../components/carousel"
import { RoomGallery } from "../components/roomGallery"
import { RoomInfo } from "./style"
import { navigate } from "gatsby"

type RoomData = PageProps & {
  roomName: "Floor3" | "Floor2" | "Floor1"
}

const RoomDetail: React.FC<RoomData> = props => {
  console.log(props)
  const key = props.roomName
  const data = roomsData[key]
  return (
    <Layout
      data={{
        imgUrl: data.heroImg,
        heading: data.name,
        description: "Buchen Sie jetzt",
        ShowAvailability: true,
      }}
    >
      <Seo title="our rooms" />
      <Box
        sx={{
          padding: "130px 0 130px",
          "@media screen and (max-width: 767px)": {
            padding: "50px 0 0",
          },
          "button:hover": {
            borderColor: "#848a7d",
            backgroundColor: "#848a7d",
            color: "#fff",
          },
          li: {
            fontSize: "20px",
            lineHeight: "30px",
            "@media screen and (max-width: 767px)": {
              marginBottom: "11px !important",
              fontSize: "16px",
              lineHeight: "24px",
            },
          },
        }}
      >
        <Container maxWidth="lg">
          <RoomInfo>
            <h2>{data.name}</h2>
            <p>{data.description}</p>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/contact/")}
            >
              Buchen
            </Button>

            <ul>
              {data.roomDetail.map((item, i) => (
                <li key={`index-${i}`}>{item}</li>
              ))}
            </ul>
          </RoomInfo>
        </Container>
      </Box>

      <ReactCarousel
        sx={{ background: "transparent", padding: "0px 0px 160px" }}
        imgUrl={data.slider.imgUrl}
      />
      <Box
        sx={{
          img: { width: "100%", height: "" },
          ".roomDiagramMobile": {
            display: "none",
            "@media screen and (max-width: 767px)": {
              display: "block",
            },
          },
          ".roomDiagram": {
            "@media screen and (max-width: 1440px)": {
              height: "651px",
            },
            "@media screen and (max-width: 1080px)": {
              height: "auto",
            },
            "@media screen and (max-width: 767px)": {
              display: "none",
            },
          },
        }}
      ></Box>
      <Box
        sx={{
          padding: "100px 0",
          "@media screen and (max-width: 767px)": {
            paddingTop: 0,
          },
        }}
      >
        <Container maxWidth="lg">
          <RoomGallery {...props} />
        </Container>
      </Box>
    </Layout>
  )
}

export default RoomDetail
